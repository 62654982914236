export const settingsItem = {
  alignItems: 'flex-start',
  paddingBottom: '1.25rem',
  paddingTop: '1.25rem',
  '@media (min-width: 60rem)': {
    alignItems: 'center',
  },
};

export const settingsGrid = {
  '@media (min-width: 60rem)': {
    alignItems: 'center',
  },
  '& > *:last-child': {
    '@media (min-width: 60rem)': {
      textAlign: 'right',
    },
  },
};

export const settingsIcon = {
  marginRight: '1rem',
  width: '4.125rem',
  '& svg': {
    display: 'block',
    margin: '0 auto',
  },
};
